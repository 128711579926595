import React from 'react'
import './Payment.css'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'

function Payment(props) {
    return (
        <div className='payment-main-container'>
            <div className='payment-container requires-no-scroll'>
                <div className='payment-header'>
                    <h1>Payment Processing</h1>
                </div>
                <div className='payment-body'>
                    {(props.payment !== "success" && props.payment !=="fail") && (<>
                        <p className='payment-refresh'>Do Not Refresh This Page</p>
                        <div className="loading-spinner-container">
                            <div className="loading-spinner-r"></div>
                            <div className="loading-spinner-l"></div>
                        </div>
                    </>)}
                    {props.payment === "success" && (<>
                        <p className='payment-success'>Payment Successful</p>
                        <div className='checkmark-container'>
                            <BsCheckCircleFill className='checkmark' />
                        </div>
                    </>)}
                    {props.payment === "fail" && (<>
                        <p className='payment-fail'>Payment Failed</p>
                        <div className='cross-container'>
                            <BsXCircleFill className='cross' />
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    )
}

export default Payment
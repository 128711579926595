import React, { useRef } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { Suspense } from 'react';
import useScrollPosition from '../hooks/scroll';
import { scrollCalculate } from '../helpers/scrollcalculate';
import { Environment } from '@react-three/drei';
import model1 from './models/ejd.glb';
import bg from './back.jpg';
import './Background.css';


function Background() {
  const backgroundRef = useRef(null);
  const gltf = useLoader(GLTFLoader, model1);
  const scrollposition = useScrollPosition();
  const width = window.innerWidth;
  
  function Animation() {
    //start -10 all

    useFrame(() => { // Use useFrame hook to update animations
      if (backgroundRef.current) {
        backgroundRef.current.position.x = scrollCalculate(width>700?-7.5:-3.5, 0, 0.7, 0.8, scrollposition);
        backgroundRef.current.position.z = scrollCalculate(0, 5, 0.8, 1, scrollposition);
        backgroundRef.current.rotation.x = scrollCalculate(width>700?-1.57:-1, 0, 0.7, 0.8, scrollposition);
        backgroundRef.current.rotation.y = scrollCalculate(width>700?1.16:1, 0, 0.7, 0.8, scrollposition);
        backgroundRef.current.rotation.z += 0.01;

      }
    });
  }
  //Add bg as the background style for canvas
  const background = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    zIndex: '-1',
    width: '100%',
    position: 'fixed',
    // backgroundSize: "120%",
  };

  return (
    <>
    <div style={background} className='event-stars-background'></div>
      {/* <Canvas style={background} className='event-stars-background'>

        <Suspense fallback={null}>
          <Environment preset="city" />
          <directionalLight intensity={1.5} />
          <primitive object={gltf.scene}
            scale={0.1}
            ref={backgroundRef} />
          <Animation />
        </Suspense>
      </Canvas> */}
      {/* <form style={{color:"white"}}>
        <label>
          Position X: {posX}
          <input
            type="range"
            name="posX"
            value={posX}
            min={-20}
            max={20}
            step={0.1} // Set step to 0.1 for decimal values
            onChange={handleSliderChange}
          />
        </label>
        <label>
          Position Y: {posY}
          <input
            type="range"
            name="posY"
            value={posY}
            min={-20}
            max={20}
            step={0.1} // Set step to 0.1 for decimal values
            onChange={handleSliderChange}
          />
        </label>
        <label>
          Position Z: {posZ}
          <input
            type="range"
            name="posZ"
            value={posZ}
            min={-20}
            max={20}
            step={0.1} // Set step to 0.1 for decimal values
            onChange={handleSliderChange}
          />
        </label>
        <label>
          Rotation X: {rotX}
          <input
            type="range"
            name="rotX"
            value={rotX}
            min={-20}
            max={20}
            step={0.01} // Set step to 0.1 for decimal values
            onChange={handleSliderChange}
          />
        </label>
        <label>
          Rotation Y: {rotY}
          <input
            type="range"
            name="rotY"
            value={rotY}
            min={-20}
            max={20}
            step={0.01} // Set step to 0.1 for decimal values
            onChange={handleSliderChange}
          />
        </label>
      </form> */}
    </>
  );
}

export default Background;

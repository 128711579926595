import React, { useEffect, useState } from 'react';
import './Card.css';
import useScreenWidth from '../hooks/screenWidth';
import { scrollCalculate } from '../helpers/scrollcalculate';
import useScrollPosition from '../hooks/scroll';
import Sp0 from './media/1.png';
import Sp1 from './media/2.png';
import Sp2 from './media/3.png';
import Sp3 from './media/4.png';
import Sp4 from './media/5.png';
import Sp5 from './media/6.png';
import backGround from './speaker/1b.png';
import SpeakerBackground from '../media/Image 2.jpg';


function Speakers(props) {
  const [current, setCurrent] = useState(0)
  const [classes, setClasses] = useState({})
  useEffect(() => {
    // Preload the images
    const images = [Sp0, Sp1, Sp2, Sp3, Sp4, Sp5, backGround, SpeakerBackground];
    images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
  }, []);
  const range = Array.from({ length: 6 }, (_, index) => index + 1);
  const width = useScreenWidth();
  const scrollposition = useScrollPosition();

  const data = [{
    name: 'Nidhi Sudhan',
    topic: 'Generative AI: Ups and Downs',
    desg: `Media and Tech Specialist.\nCo-founder, Citizen Digital Foundation.`,
  }, {
    name: 'Sidharth S Kumar',
    topic: 'Designing for Humans',
    desg: 'Product Designer\nMicrosoft',
  },
  {
    name: "Arjun Jagdish",
    topic: "File System in AWS",
    desg: `System Engineer II\nAmazon Web Services, Ireland`,
  },
  {
    name: "Kevin Alexander",
    topic: "Entrepreneurship and Web 3.0",
    desg: `Managing Partner\nNBT biz`,
  },
  {
    name: "Sarath Prabhavu J",
    topic: "Star Gazing & Astrophotography",
    desg: `Astrophotographer\nCo-founder, Starvoirs`,
  },
  {
    name: "Ammu Nair",
    topic: "Building Your Personal Brand on Social Media",
    desg: `Marketing Head\nNBT Business Solutions`,
  },
  ]
  useEffect(() => {
    setCurrent(Math.floor(scrollCalculate(0, 5.8, 0, 0.9, scrollposition)))
  }, [scrollposition])
  useEffect(() => {
    setClasses({
      voyager: {
        transform: `translateY(${(width>780?-108:-102) * current}%)`
      },
      speaker: {
        transform: `translateY(${(width>780?-125:-107) * current}%)`
      },
      desg: {
        transform: `translateY(${(width>780?-108:-118) * current}%)`
      },
      topic: {
        transform: `translateY(${(width>780?-120:-106) * current}%)`,
        gap: "15px",
      },
    })
  }, [current])
  // const height = (width < 780 && current===5) ?  
  const boxStyle = {
    width: `${width > 780 ? '50%' : '80%'}`,
    height: `${width > 780 ? '65%' : '75%'}`,
    // backgroundColor: '#f41360',
  };
  return (
    <div style={{ opacity:`${scrollposition<0.95?1:0}`,transition: "opacity 0.5s ease-in" }}>
      <div className="glow-container" style={{ position: "fixed" }}>
        <div className='augs' data-augmented-ui style={boxStyle}>
          <div className="Heading">#SPEAKER</div>
          <div className='Text-container'>
            <div className='Text-container-1'>
              <h1 className='SpHeading'><span className="blue">V</span>OY<span className="blue">A</span>GER <span className="blue">#</span>
                <span className='voyager-no-container'>
                  <span className='voyager-no-sub' style={classes.voyager}>
                    {range.map((item, index) => {
                      return <span className='voyager-no'>{item}</span>
                    })}
                  </span>
                </span>
              </h1>
              <p className='FuturName'> NAME : <span className='tagValue'>
                <span className="speaker-container">
                  <span className='speaker-sub' style={classes.speaker}>
                    {data.map((item, index) => {
                      return <span className='speaker-text'>{item.name}</span>
                    })}
                  </span>
                </span>
              </span></p>
              <p className='FuturName'> DESIGNATION : <span className='tagValue' style={{ whiteSpace: "pre-line" }}>
                <span className="designation-container">
                  <span className='designation-sub' style={classes.desg}>
                    {data.map((item, index) => {
                      return <span className='designation-text'>{item.desg}</span>
                    })}
                  </span>
                </span>
              </span></p>
              <p className='FuturName'> TOPIC : <span className='tagValue'>
                <span className="speaker-container" style={{height:"25px"}}>
                  <span className='speaker-sub' style={classes.topic}>
                    {data.map((item, index) => {
                      return <span className='speaker-text'>{item.topic}</span>
                    })}
                  </span>
                </span>
              </span> </p>
            </div>
          </div>
          <div className="hero">
            <img className="CardBack" src={SpeakerBackground} alt='Background' />
            <img className='popup-image' style={{ opacity: `${current === 0 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp0} alt="Speaker" />
            <img className='popup-image' style={{ opacity: `${current === 1 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp1} alt="Speaker" />
            <img className='popup-image' style={{ opacity: `${current === 2 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp2} alt="Speaker" />
            <img className='popup-image' style={{ opacity: `${current === 3 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp3} alt="Speaker" />
            <img className='popup-image' style={{ opacity: `${current === 4 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp4} alt="Speaker" />
            <img className='popup-image' style={{ opacity: `${current === 5 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={Sp5} alt="Speaker" />
            <img className='SpBackground' src={backGround} alt="Speaker Background Box" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Speakers;

import { Suspense, useRef } from 'react';
import model1 from './space_boi.glb';
import useScrollPosition from '../../hooks/scroll';
import { useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Canvas } from '@react-three/fiber';
import { scrollCalculate } from '../../helpers/scrollcalculate';

function ThreeD() {
    const scrollRef = useRef(null);
    const gltf = useLoader(GLTFLoader, model1);
    const scrollposition = useScrollPosition();
    const classes = {
        root: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    function Frame(){
        useFrame(() => {
            if(scrollRef.current) {
                scrollRef.current.rotation.y = scrollCalculate(0,8.1,0,1.5,scrollposition);
                scrollRef.current.position.z = scrollCalculate(0,4.18,0.4,1,scrollposition);
            }
        })//, [scrollposition]);
    }


    return (
        <Canvas style={classes.root}>
            <Suspense fallback={null}>
                <primitive object={gltf.scene}
                    ref={scrollRef}
                    rotation={[0.4, 0, 0]}
                    position={[0, -1.5, 0]}
                    scale={[0.5,0.5,0.5]}
                    // lookAt={[20,10,0]}
                    
                />
                <Frame />
            </Suspense>
        </Canvas>
    );
}

export default ThreeD;

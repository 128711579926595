import React,{ useState, useEffect } from 'react'
import useScrollPosition from '../hooks/scroll'
import Background from './Background'
import EventCard from './EventCard'

function Events(props) {
    const scrollposition = useScrollPosition()
    const [start, setStart] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 100);
    }, []);
    useEffect(() => {
        if (scrollposition > 0.98) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(3)
            }, 2000);
        }
        else if (scrollposition <= 0 && start) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(1)
            }, 2000);
        }
    }, [scrollposition]);
    useEffect(() => {
        setStart(true)
    }, [scrollposition]);

    const classes = {
        root: {
            height: "600vh",
            width: "100%",
        },
        fixed: {
            position: "fixed"
        }
    }
    return (
        <div style={classes.root} >
            <EventCard />
            <Background /> 
        </div>
    )
}

export default Events
import React from 'react'
import './Background.css'

//See through the eyes of innovation
function Background() {
    return (
        <div className='background-footer'>
            <div id='footer-stars'></div>
            <div id='footer-stars2'></div>
            <div id='footer-stars3'></div>
            <div id='footer-horizon'>
                <div class='footer-glow'></div>
            </div>
            <div id='footer-earth'></div>
            <div id='footer-title'>
                KAIZEN
            </div>
            <div id='footer-subtitle'>
                <span>
                    SEE
                </span>
                <span>
                    THROUGH
                </span>
                <span>
                    THE
                </span>
                <span>
                    EYES
                </span>
                <span>
                    OF
                </span>
                <span>
                    INNOVATION
                </span>
            </div>

        </div>
    )
}

export default Background
import React, { useEffect, useState } from 'react';
import './App.css';
//Pages
import Logo from './components/Logo/Logo';
import About from './components/About/About';
import Events from './components/Events/Events';
import Card from './components/Cards/Card';
import Sponsor from './components/Sponsor/Sponsor';
import Registration from './components/Registration/Forms';
import Footer from './components/Footer/Footer';
import { Routes, Route } from 'react-router-dom';
//Transitions
import Hyper from './components/Wrap/Hyper';
import Loader from './components/Loader/Loader';
import Transition from './components/Transition/Transition';
// import Policy from './components/Policy/Policy';

function App() {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 3000)
  }, [])
  const time = parseInt(localStorage.getItem('time') || "5000")
  useEffect(() => {
    setTimeout(() => {
      if (time > 1000) {
        localStorage.setItem('time', "1000")
      }
      setLoaded(true)
    }, time)
  }, [])
  const [current, setCurrent] = useState(0)
  const [transition, setTransition] = useState(false)
  const elements = [
    <Logo setCurrent={setCurrent} setTransition={setTransition} />,
    <About setCurrent={setCurrent} setTransition={setTransition} />,
    <Events setCurrent={setCurrent} setTransition={setTransition} />,
    <Card setCurrent={setCurrent} setTransition={setTransition} />,
    <Sponsor setCurrent={setCurrent} setTransition={setTransition} />,
    <Footer setCurrent={setCurrent} setTransition={setTransition} />,
    <Registration setCurrent={setCurrent} setLoading={setLoading} />
  ]
  // const Button = ({ pos }) => {
  //   const handleClick = () => {
  //     setLoading(true)
  //     setTimeout(() => {
  //       setLoading(false)
  //       setCurrent(6)
  //       window.history.pushState({}, "", "/register")
  //     }, 2000)
  //   }
  //   return (
  //     <a href='/policy' className={`btn-ne ${pos}`}>
  //       <span></span>
  //       <span></span>
  //       <span></span>
  //       <span></span>
  //       Policy
  //     </a>
  //   )
  // }

  return (
    <div id='App'>
      <Routes>
        <Route path="/register" element={<Registration setCurrent={setCurrent} setLoading={setLoading} />} />
        {/* <Route path="/policy" element={<Policy />} /> */}
        <Route path="*" element={
          <>
            {loading ? <Transition /> : (
              <>
                {/* {loaded && current === 0 && <Button pos="top" />} */}
                {/* {current === 3 && <Button pos="bottom" />} */}
                {loaded ? current !== false && elements[current] : <Loader />}
                {transition && <Hyper current={current} setCurrent={setCurrent} setTransition={setTransition} />}
              </>
            )}
          </>} />
      </Routes>
    </div >
  );
}

export default App;

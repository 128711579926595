import React from 'react'
import './Pwn.css'

function Pwn() {
    return (
        <p class="pwn-text-container">
            <span class="pwn-text">
                JADBERY
            </span>
        </p>
    )
}

export default Pwn
import React from 'react'
import './Loader.css'
import { FaEllipsisH } from 'react-icons/fa'

function Loader() {
    return (
        <div className='background-loader'>
            <div className="loader-wrapper">
                <div className="loader-outer">
                    <div className="loader-inner">
                        <FaEllipsisH />
                    </div>
                </div>
                <h1 className='h1-loader'><span className='span-loader'>LOADING</span></h1>
            </div>
        </div>
    )
}

export default Loader
import React, { useState, useEffect } from 'react'
import useScrollPosition from '../hooks/scroll'
import './Card.css';
import SphereAnimation from './bgAnimmation/World';
import Speakers from './Speakers';
import Workshops from './Workshops'

function Card(props) {
  const scrollposition = useScrollPosition()
  const [start, setStart] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  useEffect(() => {
    if (scrollposition >= 0.99) {
      props.setTransition(true)
      props.setCurrent(false)
      setTimeout(() => {
        props.setCurrent(4)
      }, 2000);
    }
    else if (scrollposition <= 0 && start) {
      props.setTransition(true)
      props.setCurrent(false)
      setTimeout(() => {
        props.setCurrent(2)
      }, 2000);
    }
  }, [scrollposition]);
  useEffect(() => {
    setStart(true)
  }, [scrollposition]);
  return (
    <div style={{ height: "700vh" }}>
      <SphereAnimation />
      <Speakers />
      <Workshops />
    </div>
  );
}

export default Card;

import React from 'react'
import './stars.css'

function Stars() {
  return (
    <div>
    <div class="bg-animation">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
    </div>

</div>



    //   <div>
    //       <div class="background-container">
    //           <div class="stars"></div>
    //           <div class="twinkling"></div>

    //       </div>
    //   </div>
  )
}

export default Stars;
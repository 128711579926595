import React from 'react';
import './TitleText.css'; // Import external CSS file
import useScrollPosition from '../hooks/scroll';
import { scrollCalculate } from '../helpers/scrollcalculate';

const TitleText = () => {
  // const [isTextHolder, setIsTextHolder] = useState(true);
  const scrollposition = useScrollPosition();

  // const handleResetClick = () => {
  //   setIsTextHolder(false);
  //   setTimeout(() => {
  //     setIsTextHolder(true);
  //   }, 300);
  // };
  const title = {
    opacity: `${scrollCalculate(1, 0, 0.9, 1, scrollposition)}`,
  }
  return (
    <div className='glass' style={title}>
      <div className='text-holder'>
        <h2 className="title">Kaizen</h2>
        <p className="paragraph">Kaizen is a nation-wide technical fest that brings together some of the brightest minds in the
          country to showcase their technical skills and creativity. Since its inception in 2013, the fest
          has attracted participants from all over the country for its melting pot of ideas, knowledge
          and innovation. More than 2.5k + participants and 60+ speakers have joined the fest, making it a grand platform for learning and networking. The name Kaizen itself means changing for the better, which reflects the core essence of the fest. It is a culmination of culture, technology, innovation, and humanity. Boasting a plethora of events, workshops, speakers and cultural events, it is sure to strike a chord inside everyone who stands testimony to it.</p>
      </div>
    </div>
  );
};

export default TitleText;

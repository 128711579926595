import React, { useEffect, useState } from 'react'
import useScrollPosition from '../hooks/scroll'
import CanvasStars from './CanvasStars';
import TitleText from './TitleText';

function About(props) {
    const scrollposition = useScrollPosition()
    const [classes, setClasses] = useState({})
    const [start, setStart] = useState(false)
    useEffect(() => {
        setStart(true)
    }, [scrollposition]);
    useEffect(() => {
        if (scrollposition > 0.98) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(2)
            }, 2000);
        }
        else if (scrollposition <= 0 && start) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(0)
            }, 2000);
        }
    }, [scrollposition]);
    useEffect(() => {
        window.scrollTo(0, 200);
        setClasses({
            root: {

                position: "fixed",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                height: "100vh",
            },
            // img:{
            //     width: '100%',
            //     height: '100%',
            //     transform: `scale(1.3) translate(${mousePos.x / 10}px, ${mousePos.y / 10}px)`,
            // },
            // starsimg:{
            //     position: 'absolute',
            //     top: '50%',
            //     left: '50%',
            //     transform: `rotate(90deg) translate(-50%, -50%) scale(1) translate(${mousePos.x / 15}px, ${mousePos.y / 15}px)`,
            // }
        });
    }, []);
    // const handleMouseMove = (event) => {
    //     setMousePos({ x: event.clientX, y: event.clientY });
    // }
    return (
        <div style={{ height: "200vh" }}>
            <div style={classes.root}>
                <CanvasStars />
                <TitleText />
            </div>
        </div>
    )
}

export default About

import React from 'react'
import './Sky.css'

function Sky() {
    return (
        <div class="container">
            <div class="sky">
                <div class="stars"></div>
                <div class="stars1"></div>
                <div class="stars2"></div>
                <div class="shooting-stars"></div>
            </div>
        </div>
    )
}

export default Sky


import React from 'react'
import { useEffect, useState, useRef, Suspense } from 'react';
import useScrollPosition from '../hooks/scroll';
import { scrollCalculate } from '../helpers/scrollcalculate';
import k from '../media/individual/SVG/k.svg'
import a from '../media/individual/SVG/a.svg'
import i from '../media/individual/SVG/i.svg'
import z from '../media/individual/SVG/z.svg'
import e from '../media/individual/SVG/e.svg'
import rocket from '../media/individual/SVG/rocket.svg'
import round from '../media/individual/SVG/round.svg'
import ThreeD from './ThreeD/ThreeD';


function Logo(props) {
  const divRef = useRef(null);
  const [classes, setClasses] = useState({})
  const scrollposition = useScrollPosition();
  useEffect(() => {
    // Preload the images
    const images = [k, a, i, z, e, rocket, round];
    images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
  }, []);
  useEffect(() => {
    setClasses({
      root: {
        backgroundColor: 'black',
        width: "100%",
        height: "700vh",
      },
      part1: {
        transition: "transform 0.05s ease-in",
        position: 'fixed',
        // width: `${50 + 240 * scrollposition}%`,
        width: "50%",
        filter: `blur(${scrollposition * 15}px)`,
        top: '50%',
        display: `${scrollposition * 100 > 40 ? 'none' : 'block'}`,
        left: '50%',
        transform: `translate(-46%, -50%) scale(${scrollCalculate(1, 6, 0, 0.4, scrollposition)})`,
      },
      part2: {
        transition: "transform 0.05s ease-in",
        position: 'fixed',
        width: `${scrollposition * 100 > 20 ? 50 + 390 * (scrollposition - 0.20) : 50}%`,
        filter: `blur(${scrollposition * 100 > 20 ? 15 * (scrollposition - 0.20) : 0}px)`,
        display: `${scrollposition * 100 > 50 ? 'none' : 'block'}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-46%, -50%)',
      },
      part3: {
        transition: "transform 0.05s ease-in",
        position: 'fixed',
        // width: `${scrollposition * 100 > 35 ? 50 + 540 * (scrollposition - 0.35) : 50}%`,
        width: "50%",
        filter: `blur(${scrollposition * 100 > 35 ? 15 * (scrollposition - 0.35) : 0}px)`,
        display: `${scrollposition * 100 > 70 ? 'none' : 'block'}`,
        top: '50%',
        left: '50%',
        transform: `translate(-46.6%, -50%) scale(${scrollCalculate(1, 6, 0.35, 0.7, scrollposition)})`,
      },
      part4: {
        transition: "transform 0.05s ease-in",
        position: 'fixed',
        // width: `${scrollposition * 100 > 50 ? 50 + 790 * (scrollposition - 0.50) : 50}%`,
        width: "50%",
        filter: `blur(${scrollposition * 100 > 50 ? 15 * (scrollposition - 0.50) : 0}px)`,
        display: `${scrollposition * 100 > 70 ? 'none' : 'block'}`,
        top: '50%',
        left: `${scrollposition * 100 > 50 ? 50 + 45 * (scrollposition - 0.50) : 50}%`,
        transform: `translate(-46.6%, -50%) scale(${scrollCalculate(1, 6, 0.5, 0.7, scrollposition)})`,
      },
      part5: {
        transition: "transform 0.05s ease-in",
        position: 'fixed',
        // width: `${scrollposition * 100 > 59 ? 50 + 940 * (scrollposition - 0.59) : 50}%`,
        width: "50%",
        filter: `blur(${scrollposition * 100 > 59 ? 15 * (scrollposition - 0.59) : 0}px)`,
        display: `${scrollposition * 100 > 80 ? 'none' : 'block'}`,
        top: '50%',
        left: `${scrollposition * 100 > 65 ? 50 + 140 * (scrollposition - 0.65) : 50}%`,
        transform: `translate(-46.6%, -50%) scale(${scrollCalculate(1, 6, 0.65, 0.8, scrollposition)})`
      },
      part6: {
        transition: "width 0.05s ease-in",
        position: 'fixed',
        width: `${scrollposition * 100 > 73 ? 50 + 1947 * (scrollposition - 0.73) : 50}%`,
        // width: "50%",
        // opacity: `${scrollposition * 100 > 73 ? 15 * (scrollposition - 0.73) : 0}px`,
        top: '50%',
        left: `50%`,
        transform: `translate(-46.6%, -50%)`
      },
      rocket: {
        position: 'fixed',
        transition: "width 0.05s ease-in",
        width: `${scrollposition * 100 > 73 ? 50 + 847 * (scrollposition - 0.73) : 50}%`,
        // width: "50%",
        // filter: `blur(${scrollposition * 100 > 73 ? 15 * (scrollposition - 0.73) : 0}px)`,
        // opacity: `${scrollposition * 100 > 73 ? 1.3 - (scrollposition) : 1}`,
        top: '50.2%',
        left: `50.15%`,

        transform: `translate(-46.6%, -50%)`,
      },

    })
  }, [scrollposition])
  useEffect(() => {
    if (scrollposition > 0.98) {
      props.setTransition(true)
      props.setCurrent(false)
      setTimeout(() => {
        props.setCurrent(1)
      }, 2000);
    }
  }, [scrollposition])
  return (
    <div style={classes.root}>
      <ThreeD />
      <Suspense>
        <div ref={divRef}>
          {scrollposition <= 0.99 && (
            <>
              {/*{scrollposition * 100 < 40 && <img src={part1} alt="part1" style={classes.part1} />}
            {scrollposition * 100 < 50 && <img src={part2} alt="part2" style={classes.part2} />}
            {scrollposition * 100 < 70 && <img src={part3} alt="part3" style={classes.part3} />}
            {scrollposition * 100 < 70 && <img src={part4} alt="part4" style={classes.part4} />}
          {scrollposition * 100 < 80 && <img src={part5} alt="part5" style={classes.part5} />} */}
              {/* {scrollposition * 100 < 99 && <img src={part6} alt="part6" style={classes.part6} />} */}
              {scrollposition * 100 < 40 && <img src={k} alt="k" style={classes.part1} />}
              {scrollposition * 100 < 50 && <img src={a} alt="a" style={classes.part2} />}
              {scrollposition * 100 < 70 && <img src={i} alt="i" style={classes.part3} />}
              {scrollposition * 100 < 70 && <img src={z} alt="z" style={classes.part4} />}
              {scrollposition * 100 < 80 && <img src={e} alt="e" style={classes.part5} />}
              {scrollposition * 100 < 90 && <img src={rocket} alt="rocket" style={classes.rocket} />}
              {scrollposition * 100 < 95 && <img src={round} alt="round" style={classes.part6} />}
            </>
          )}
        </div>
      </Suspense>
    </div>
  );

}

export default Logo
import React, { useState, useEffect } from "react";
import "./Forms.css";
import Background from "../Background/Background";
import Payment from "./Payment";

// const handlePayment = (e) => {
//   if (rzp) {
//     rzp.open();
//     e.preventDefault();
//   }
// };
function chunkString(str) {
  const maxLength = 255;

  if (str.length <= maxLength) {
      return { 1: str };
  }

  const chunks = [];
  let start = 0;

  while (start < str.length) {
      chunks.push(str.substr(start, maxLength));
      start += maxLength-1;
  }

  const result = {};
  chunks.forEach((chunk, index) => {
      result[index + 1] = chunk;
  });

  return result;
}


function Registration(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [college, setCollege] = useState("");
  const [Department, setDept] = useState("");
  const [year, setYear] = useState(1);
  const [ieeeMember, setIeeeMember] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [foodPreference, setFoodPreference] = useState("veg");
  const [workshopPreference, setWorkshopPreference] = useState("Workshop 1");
  const [referalCode, setReferalCode] = useState("");
  const [loading, setLoading] = useState()
  const [payment, setPayment] = useState("")
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  useEffect(() => {
    loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
  }, []);
  const handleSubmit = (e) => {
    //check if every field is filled
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      college === "" ||
      Department === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true)
    const formdata = {
      name,
      email,
      phone,
      college,
      Department,
      year,
      ieeeMember,
      memberId,
      foodPreference,
      workshopPreference,
      referalCode,
    }
    const notes = chunkString(btoa(JSON.stringify(formdata)))
    console.log(notes)
    fetch('http://localhost:80/payment',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => res.json())
      .then(data => {
        // console.log(data,typeof(data.id))
        const options = {
          key: 'rzp_test_oxBz0tlWGbH1ql',
          amount: '205000',
          currency: 'INR',
          name: 'Kaizen',
          image: 'https://i.ibb.co/th9DbwP/image.png',
          order_id: data.id,
          description: 'Kaizen 2021',
          notes: notes,
          theme: {
            color: '#3399cc',
          },
          handler: function (response) {
            setLoading(true)
            fetch('http://localhost:80/payment/verify',{
              method: 'POST',
              headers:{
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(response)
            }).then(res=>res.json()).then(data=>{
              console.log(data)
              if(data.status === 'success'){
                setPayment('success')
                // alert('Payment Successful')
              } else if(data.status === 'fail') {
                setPayment('fail')
                // alert('Payment Failed')
              }
            })
            setTimeout(() => {
              setLoading(false)
              //reset all the fields
              setName('')
              setEmail('')
              setPhone('')
              setCollege('')
              setDept('')
              setYear(1)
              setIeeeMember(false)
              setMemberId('')
              setFoodPreference('veg')
              setWorkshopPreference('Workshop 1')
              setReferalCode('')
              setPayment('')
            }, 4000)
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
          },
          // Add a function to handle payment failure
          // which will trigger an alert with error information
          // when the payment fails.
          // The `response` parameter will contain error information.
          // This is an optional parameter.
          // You can remove this if you don't want to handle payment failure.
          onpaymentfailure: function (response) {
            setLoading(true)
            setPayment('failed')
            setTimeout(() => {
              setLoading(false)
            }, 4000)
            // alert("Payment Failed")
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open()
      })
  };

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      props.setLoading(true);
      setTimeout(() => {
        props.setLoading(false)
        props.setCurrent(0)
      }, 2000)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="registration-form">
      <Background />
      {loading && <Payment payment={payment} />}
      <div className="form-main-container">
        <form className="form-container" name="form">
          <h1 className="registration-h1">Registration</h1>
          <div className="row">
            <input
              required
              type="text"
              value={name}
              className="balloon"
              id="name"
              placeholder="John Doe"
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="row">
            <input
              required
              type="email"
              className="balloon"
              id="email"
              placeholder="mail@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="row">
            <input
              required
              type="tel"
              className="balloon"
              id="phone"
              placeholder="1234567890"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label htmlFor="phone">Phone</label>
          </div>

          <div className="row">
            <input
              required
              type="text"
              className="balloon"
              id="college"
              placeholder="College Name"
              value={college}
              onChange={(e) => setCollege(e.target.value)}
            />
            <label htmlFor="">College</label>
          </div>
          <div className="row">
            <input
              required
              type="text"
              className="balloon"
              id="dept"
              placeholder="CSE, ECE, EEE, MECH, etc."
              value={Department}
              onChange={(e) => setDept(e.target.value)}
            />
            <label htmlFor="">Dept.</label>
          </div>
          <div className="container-form-radio">
            <p>Year of Study</p>
            <ul>
              <li>
                <input type="radio" id="year1" name="year" value={1} checked={year === 1} onChange={() => setYear(1)}
                />
                <label htmlFor="year1">1</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input type="radio" id="year2" name="year" value={2} checked={year === 2} onChange={() => setYear(2)}
                />
                <label htmlFor="year2">
                  2
                </label>
                <div className="check"></div>
              </li>
              <li>
                <input type="radio" id="year3" name="year" value={3} checked={year === 3} onChange={() => setYear(3)}
                />
                <label htmlFor="year3">
                  3
                </label>
                <div className="check"></div>
              </li>
              <li>
                <input type="radio" id="year4" name="year" value={4} checked={year === 4} onChange={() => setYear(4)}
                />
                <label htmlFor="year4">
                  4
                </label>
                <div className="check"></div>
              </li>
            </ul>
          </div>
          <div className="container-form-radio">
            <p>IEEE Member</p>
            <ul>
              <li>
                <input type="radio" id="yes" name="ieeeMember" value="yes" checked={ieeeMember} onChange={() => setIeeeMember(true)} />
                <label htmlFor="yes">Yes</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
              <li>
                <input type="radio" id="no" name="ieeeMember" value="no" checked={!ieeeMember} onChange={() => setIeeeMember(false)} />
                <label htmlFor="no">No</label>
                <div className="check"></div>
              </li>
            </ul>
          </div>
          {ieeeMember && (<div className="row">
            <input
              type="text"
              className="balloon"
              id="ieeeMemberId"
              placeholder="Member Id"
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
            />
            <label className="TextIp">Member Id</label>
          </div>
          )}

          <div className="container-form-radio">
            <p>Food Preference</p>
            <ul>
              <li>
                <input type="radio" name="food" id="veg" value="veg" checked={foodPreference === "veg"} onChange={(e) => setFoodPreference("veg")} />
                <label htmlFor="veg">Veg</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input type="radio" name="food" id="non-veg" value="non-veg" checked={foodPreference === "non-veg"} onChange={(e) => setFoodPreference("non-veg")} />
                <label htmlFor="non-veg">Non-Veg</label>
                <div className="check"></div>
              </li>
            </ul>
          </div>
          <div className="container-form-radio">
            <p>Workshop Preference</p>
            <ul>
              <li>
                <input
                  type="radio"
                  value="Workshop 1"
                  id="workshop-1"
                  checked={workshopPreference === "Workshop 1"}
                  onChange={(e) => setWorkshopPreference("Workshop 1")}
                />
                <label htmlFor="workshop-1">Workshop 1</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input
                  type="radio"
                  value="Workshop 2"
                  id="workshop-2"
                  checked={workshopPreference === "Workshop 2"}
                  onChange={(e) => setWorkshopPreference("Workshop 2")}
                />
                <label htmlFor="workshop-2">Workshop 2</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input
                  type="radio"
                  value="Workshop 3"
                  id="workshop-3"
                  checked={workshopPreference === "Workshop 3"}
                  onChange={(e) => setWorkshopPreference("Workshop 3")}
                />
                <label htmlFor="workshop-3">Workshop 3</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input
                  type="radio"
                  value="Workshop 4"
                  id="workshop-4"
                  checked={workshopPreference === "Workshop 4"}
                  onChange={(e) => setWorkshopPreference("Workshop 4")}
                />
                <label htmlFor="workshop-4">Workshop 4</label>
                <div className="check"><div className="inside"></div></div>
              </li>
              <li>
                <input
                  type="radio"
                  value="Workshop 5"
                  id="workshop-5"
                  checked={workshopPreference === "Workshop 5"}
                  onChange={(e) => setWorkshopPreference("Workshop 5")}
                />
                <label htmlFor="workshop-5">Workshop 5</label>
                <div className="check"><div className="inside"></div></div>
              </li>
            </ul>
          </div>
          <div className="row">
            <input
              type="text"
              className="balloon"
              id="referral"
              placeholder="Referral Code"
              value={referalCode}
              onChange={(e) => setReferalCode(e.target.value)}
            />
            <label htmlFor="referral">Referal</label>
          </div>
          <div className="payment-desc">
            <p className="totalrs">Total &#x20B9;2050</p>
            <p className="inclrs">(includes payment gateway fee of &#x20B9;50)</p>
          </div>
          <button className="btnr" type="button" onClick={handleSubmit}><p>Register</p></button>
        </form>
      </div>
      <p className="terms"><a className="terms-a" href="/policy" >Terms and Services</a></p>
    </div>
  );
}

export default Registration;

import React, { useEffect, useState } from 'react'
import useScrollPosition from '../hooks/scroll'
import Background from './Background'
import Pwn from './Pwn'
import Socials from './Socials'

function Footer(props) {
    useEffect(() => {
        //scroll to bottom
        window.scrollTo(0, document.body.scrollHeight);
    }, [])
    const scrollposition = useScrollPosition()
    const [start, setStart] = useState(false)
    useEffect(() => {
        if (scrollposition <= 0 && start) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(4)
            }, 2000);
        }
    }, [scrollposition]);
    useEffect(() => {
        setStart(true)
    }, [scrollposition]);
    const footer = {
        position: "fixed",
        bottom: "0",
        width: "100%",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontFamily: "monospace",
    }
    return (
        <div style={{ height: "110vh" }}>
            <Background />
            <Pwn />
            {/* <Socials /> */}
            <p style={footer} >&#169;Copyright 2024, All Rights Reserved | Jadbery</p>
        </div>
    )
}

export default Footer
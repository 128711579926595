import React, { useEffect, useState } from 'react';
import './Workshop.css';
import useScreenWidth from '../hooks/screenWidth';
import { scrollCalculate } from '../helpers/scrollcalculate';
import useScrollPosition from '../hooks/scroll';
import w1 from './media/workshop/1.jpg'
import BackImg from '../media/Image 3.jpg';


function Workshops(props) {
  const [current, setCurrent] = useState(0)
  const [classes, setClasses] = useState({})
  useEffect(() => {
    // Preload the images
    const images = [w1, BackImg];
    images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
  }, []);
  const range = Array.from({ length: 5 }, (_, index) => index + 1);
  const width = useScreenWidth();
  const scrollposition = useScrollPosition();
  const data = [
    {
      name: "Unity",
      about: "Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor ",
    },
    {
      name: "Workshop2",
      about: "Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor "
    },
    {
      name: "Workshop3",
      about: "dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor "
    },
    {
      name: "Workshop4",
      about: "sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor "
    },
    {
      name: "Workshop5",
      about: "amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit  Lorem Ipsum dolor  Lorem Ipsum dolor  Lorem Ipsum dolor "
    },

  ]
  useEffect(() => {
    setCurrent(Math.floor(scrollCalculate(0, 4.9, 0.55, 0.989, scrollposition)))
  }, [scrollposition])
  useEffect(() => {
    setClasses({
      voyager: {
        transform: `translateY(${(width>780?-108:-103) * current}%)`
      },
      speaker: {
        transform: `translateY(${(width>780?-125:-105) * current}%)`
      },
      desg: {
        transform: `translateY(${(width>780?-97:-98) * current}%)`
      }
    })
  }, [current])
  const boxStyle = {
    width: `${width > 780 ? '50%' : '80%'}`,
    height: `${width > 780 ? '65%' : '75%'}`,
    // backgroundColor: '#f41360',
  };

  return (
    <div style={{ opacity: `${scrollposition > 0.55 ? 1 : 0}`, transition: "opacity 0.5s ease-in" }}>
      <div className="glow-container-work" style={{ position: "fixed" }}>
        <div className='augs-work' data-augmented-ui style={boxStyle}>
          <div className="Heading-work">#WORKSHOP</div>
          <div className='Text-container-work'>
            <div className='Text-container-work-1'>
              <h1 className='SpHeading-work'><span className="violet">WO</span>RK<span className="violet">S</span>HOP <span className="violet">#</span>
                <span className='work-no-container'>
                  <span className='work-no-sub' style={classes.voyager}>
                    {range.map((item, index) => {
                      return <span className='work-no'>{item}</span>
                    })}
                  </span>
                </span>
              </h1>
              <p className='FuturName-work'> NAME : <span className='tagValue-work'>
                <span className="worker-name-container">
                  <span className='worker-name-sub' style={classes.speaker}>
                    {data.map((item, index) => {
                      return <span className='worker-name-text'>{item.name}</span>
                    })}
                  </span>
                </span>
              </span></p>
              <p className='FuturName-work'> ABOUT : <span className='tagValue-work' style={{ whiteSpace: "pre-line" }}>
                <span className="about-work-container">
                  <span className='about-work-sub' style={classes.desg}>
                    {data.map((item, index) => {
                      return <span className='about-work-text'>{item.about}</span>
                    })}
                  </span>
                </span>
              </span></p>
            </div>
          </div>
          <div className="hero-work">
            <img className="CardBack-work" src={BackImg} alt='Background' />
            <img className='popup-image-work' style={{ opacity: `${current === 0 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={w1} alt="Speaker" />
            <img className='popup-image-work' style={{ opacity: `${current === 1 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={w1} alt="Speaker" />
            <img className='popup-image-work' style={{ opacity: `${current === 2 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={w1} alt="Speaker" />
            <img className='popup-image-work' style={{ opacity: `${current === 3 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={w1} alt="Speaker" />
            <img className='popup-image-work' style={{ opacity: `${current === 4 ? 1 : 0}`, transition: 'opacity 0.2s ease-in' }} src={w1} alt="Speaker" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Workshops;

import React, { useEffect } from 'react';
import './Hyper.css';
import useScrollPosition from '../hooks/scroll';

function Hyper(props) {
    const scrollposition = useScrollPosition();
    useEffect(() => {
        setTimeout(() => {
            props.setTransition(false);
        }, 3000);
    }, [])
    const classes = {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflow: 'hidden',
            background:"black",

        }
    }
    return (
        <div style={classes.root} className='hyper-container'>
            <div className="scene">
                <div className="wrap">
                    <div className="wall wall-right"></div>
                    <div className="wall wall-left"></div>
                    <div className="wall wall-top"></div>
                    <div className="wall wall-bottom"></div>
                    <div className="wall wall-back"></div>
                </div>
                <div className="wrap">
                    <div className="wall wall-right"></div>
                    <div className="wall wall-left"></div>
                    <div className="wall wall-top"></div>
                    <div className="wall wall-bottom"></div>
                    <div className="wall wall-back"></div>
                </div>
            </div>

        </div>
    )
}

export default Hyper
// import { useState, useEffect } from 'react';

// function useScrollPosition(ref) {
//     const [position, setPosition] = useState(0);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (!ref.current) return;
//             const rect = ref.current.getBoundingClientRect();
//             setPosition(Math.abs(rect.top/(rect.height-window.innerHeight)));
//         };

//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, [ref]);

//     return position;
// }

// export default useScrollPosition;

import { useState, useEffect } from 'react';

const useScrollPosition = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    const percentScrolled = (scrollTop / scrollHeight);
    setScrollPercentage(percentScrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPercentage.toFixed(3);
};

export default useScrollPosition;

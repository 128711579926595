import React from 'react';
import './Planet.css';
import Sky from './Sky';

const Planet = () => {
    return (
        <>
            <Sky />
            <div id="earth"></div>
        </>
    );
};

export default Planet;

import React, { useState, useEffect } from "react";
import "./Background.css";

function Background() {
  const [clicked, setClicked] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos < prevScrollPos) {
        // User is scrolling up
        const scrollDistanceFromTop = currentScrollPos;
        const totalScrollDistance =
          document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercentageFromTop =
          (scrollDistanceFromTop / totalScrollDistance) * 100;

        if (scrollPercentageFromTop <= 25) {
          setClicked(true);
          handleClick();
        }
      } else {
        // User is scrolling down
        const scrollDistanceFromBottom =
          document.documentElement.scrollHeight -
          currentScrollPos -
          window.innerHeight;
        const totalScrollDistance =
          document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercentageFromBottom =
          (scrollDistanceFromBottom / totalScrollDistance) * 100;

        if (scrollPercentageFromBottom <= 25) {
          setClicked(false);
          handleClick();
        }
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [prevScrollPos]);

  const handleClick = () => {
    const first = document.getElementsByClassName("first-row");
    const second = document.getElementsByClassName("second-row");
    const third = document.getElementsByClassName("third-row");

    if (clicked === false) {
      document.getElementById("sun").style.backgroundColor = "#FFAA54";
      document.getElementById("sun").style.marginTop = "130vh";
      document.getElementById("sun").style.width = "300px";
      document.getElementById("sun").style.height = "300px";
      document.getElementById("sun").style.boxShadow = "0 0 100px #fff";
      document.getElementById("sky").style.marginTop = "-100vh";
      Array.prototype.forEach.call(first, function (el) {
        el.style.borderBottom = "40vh solid #FF9A1F";
      });
      Array.prototype.forEach.call(second, function (el) {
        el.style.borderBottom = "30vh solid #E86710";
      });
      Array.prototype.forEach.call(third, function (el) {
        el.style.borderBottom = "20vh solid #692E07";
      });
    } else {
      document.getElementById("sun").style.backgroundColor = "#FFCD47";
      document.getElementById("sun").style.marginTop = "60px";
      document.getElementById("sun").style.width = "250px";
      document.getElementById("sun").style.height = "250px";
      document.getElementById("sun").style.boxShadow = "none";
      document.getElementById("sky").style.marginTop = "0";
      Array.prototype.forEach.call(first, function (el) {
        el.style.borderBottom = "40vh solid #BE87FA";
      });
      Array.prototype.forEach.call(second, function (el) {
        el.style.borderBottom = "30vh solid #9781F0";
      });
      Array.prototype.forEach.call(third, function (el) {
        el.style.borderBottom = "20vh solid #6A72D9";
      });
    }
  };

  return (
    <div className="bg-container">
      <div id="sky">
        <div id="sun" onClick={handleClick}></div>
      </div>
      <div className="first-row" id="moun1"></div>
      <div className="first-row" id="moun2"></div>
      <div className="first-row" id="moun3"></div>
      <div className="second-row" id="moun4"></div>
      <div className="second-row" id="moun5"></div>
      <div className="second-row" id="moun6"></div>
      <div className="third-row" id="moun7"></div>
      <div className="third-row" id="moun8"></div>
      <div className="third-row" id="moun9"></div>
    </div>
  );
}

export default Background;

import React, { useRef, useState, useEffect, Suspense } from "react";
import './EventCard.css'
import proshow from './pics/proshow.jpg'
import camp from './pics/camp.jpg'
import star from './pics/star.jpg'
import trekking from './pics/trekking.jpg'
import culturals from './pics/culturals.jpg'
import  {scrollCalculate} from "../helpers/scrollcalculate";
import useScrollPosition from "../hooks/scroll";

const EventCard = () => {
    const screenRef = useRef(null);
    const nameRef = useRef(null);
    const [current, setCurrent] = useState({})
    const [currentText, setCurrentText] = useState("PROSHOW");
    const scrollposition = useScrollPosition();
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const [intervalId, setIntervalId] = useState(null);
    useEffect(() => {
        // preload the images
        const images = [proshow, camp, star, trekking, culturals];
        images.forEach((img) => {
            const image = new Image();
            image.src = img;
        });
    }, []);
    const events = [
        {name: "PROSHOW", image: proshow},
        {name: "CAMPFIRE", image: camp},
        {name: "STAR GAZING", image: star},
        {name: "TREKKING", image: trekking},
        {name: "CULTURALS", image: culturals},
    ]
    const changeText = () => {
        let iteration = 0;
        clearInterval(intervalId);

        setIntervalId(
            setInterval(() => {
                if (nameRef.current) {
                    const name = nameRef.current;
                    name.innerText = name.innerText
                        .split("")
                        .map((letter, index) => {
                            if (index < iteration) {
                                return name.dataset.value[index];
                            }

                            return letters[Math.floor(Math.random() * 26)];
                        })
                        .join("");

                    if (iteration >= nameRef.current.dataset.value.length) {
                        clearInterval(intervalId);
                    }

                    iteration += 1 / 3;
                }
            }, 10)
        );
    };
    useEffect(() => {
        setCurrent(events[Math.floor(scrollCalculate(0, 4.5, 0, 0.8, scrollposition))])
    }, [scrollposition])
    useEffect(() => {
        setCurrentText(current.name)
        changeText();
    }, [current.name])
    return (
        <>
        <Suspense fallback={null}>
            <div class="screen" ref={screenRef} style={{opacity:scrollCalculate(0.8,0,0.9,1,scrollposition)}}>
                <div class="screen-image" style={{ backgroundImage: `url(${events[0].image})`,opacity:currentText===events[0].name?0.9:0 }}></div>
                <div class="screen-image" style={{ backgroundImage: `url(${events[1].image})`,opacity:currentText===events[1].name?0.9:0 }}></div>
                <div class="screen-image" style={{ backgroundImage: `url(${events[2].image})`,opacity:currentText===events[2].name?0.9:0 }}></div>
                <div class="screen-image" style={{ backgroundImage: `url(${events[3].image})`,opacity:currentText===events[3].name?0.9:0 }}></div>
                <div class="screen-image" style={{ backgroundImage: `url(${events[4].image})`,opacity:currentText===events[4].name?0.9:0 }}></div>
                <div class="screen-overlay"></div>
                <div class="screen-content">
                    <div class="screen-user">
                        <span class="name screen-icon" ref={nameRef} data-value={currentText}>{currentText}</span>
                    </div>
                </div>
            </div>
        </Suspense>
        </>
    );
};

export default EventCard;

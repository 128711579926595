import { useState, useRef, useEffect } from "react";
import "./Transition.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { Stars } from "@react-three/drei";

function MovingStars({ speed }) {
  const starsRef = useRef(null);
  useFrame((state, delta) => {
    if (starsRef.current) {
      starsRef.current.position.z += delta * speed;
      if (starsRef.current.position.z > 0) {
        starsRef.current.position.z = -100;
      }
    }
  });

  return (
    <Stars
      ref={starsRef}
      radius={25}
      depth={50}
      count={5000}
      factor={4}
      saturation={1}
      fade={true}
      material={{
        transparent: true,
        opacity: 0.5,
      }}
    />
  );
}

function Transition() {
  const [isClicked, setIsClicked] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 1500);
    setTimeout(() => setShowCanvas(true), 250);
    setTimeout(() => setShowCanvas(false), 1500);
  };
  useEffect(() => {
    handleClick();
  }, []);
  return (
    <div className="btn-container">
      {showCanvas && (
        <Canvas
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: "100",
          }}
        >
          <MovingStars speed={500} />
        </Canvas>
      )}
      <span
        className={`color color--blue ${isClicked ? "expanded" : ""}`}
        data-value="1"
      ></span>
      <span
        className={`color color--cyan ${isClicked ? "expanded" : ""}`}
        data-value="1"
      ></span>
      <span
        className={`color color--yellow ${isClicked ? "expanded" : ""}`}
        data-value="1"
      ></span>
      <span
        className={`color color--black ${isClicked ? "expanded" : ""}`}
        data-value="1"
      ></span>
    </div>
  );
}

export default Transition;

import React, { useState, useEffect } from 'react'
import useScrollPosition from '../hooks/scroll'
import logo1 from './1.svg'
import logo2 from './evga.svg'
import logo3 from './logo-amazon.svg'
import logo4 from './tesla-9.svg'
import './Sponsor.css'
import Planet from './Planet'
import '../About/TitleText.css'

function Sponsor(props) {
    const scrollposition = useScrollPosition()
    const [start, setStart] = useState(false)
    useEffect(() => {
        //preload images
        const images = [logo1, logo2, logo3, logo4];
        images.forEach((img) => {
            const image = new Image();
            image.src = img;
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 200);
    }, []);
    useEffect(() => {
        if (scrollposition > 0.98) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(5)
            }, 2000);
        }
        else if (scrollposition <= 0 && start) {
            props.setTransition(true)
            props.setCurrent(false)
            setTimeout(() => {
                props.setCurrent(3)
            }, 2000);
        }
    }, [scrollposition]);
    useEffect(() => {
        setStart(true)
    }, [scrollposition]);
    const logos = [logo3, logo4, logo1, logo2, logo3, logo4, logo1, logo2, logo3, logo4]
    return (
        <div style={{ height: "200vh" }}>
            <div className='scontainer'>
                <Planet />
                <div className='glass-sponsor'>
                    <h1 className='sh1'>Sponsors</h1>
                    <div className='sblock'>
                        {logos.map((logo, index) => {
                            return (
                                <img key={index} className='slogo' src={logo} alt="logo" />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sponsor